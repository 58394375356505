import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { Animation, Text } from '@components';
import { useIsClientSide } from '@hooks';

const HiddenTextHeading = styled(Text.Heading1)`
  visibility: hidden;
`;

const CONFIG = {
  firstIntervalTimeout: 200,
  secondIntervalTimeout: 15,
  maximumGrowPoint: 70,
};

export const TitleWithIncrementalDiscount = ({
  finalDiscountPercentage,
  i18nKey,
  components,
  values,
}) => {
  const [discountPercentage, setDiscountPercentage] = useState(0);

  let interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(
      () => setDiscountPercentage((prevState) => ++prevState),
      CONFIG.firstIntervalTimeout
    );
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    if (typeof finalDiscountPercentage === 'number') {
      clearInterval(interval.current);
      interval.current = setInterval(
        () => setDiscountPercentage((prevState) => ++prevState),
        CONFIG.secondIntervalTimeout
      );
    }
    return () => clearInterval(interval.current);
  }, [finalDiscountPercentage]);

  useEffect(() => {
    if (
      (finalDiscountPercentage && discountPercentage >= finalDiscountPercentage) ||
      (!finalDiscountPercentage && discountPercentage >= CONFIG.maximumGrowPoint)
    ) {
      clearInterval(interval.current);
    }
  }, [discountPercentage, finalDiscountPercentage]);

  return (
    <Trans i18nKey={i18nKey} values={{ ...values, discountPercentage }} components={components} />
  );
};

export const FadeInTitle = ({ children, ...textProps }) => {
  const { isClientSide } = useIsClientSide();

  return isClientSide ? (
    <Animation.FadeIn as="span">
      <Text.Heading1 {...textProps}>{children}</Text.Heading1>
    </Animation.FadeIn>
  ) : (
    <HiddenTextHeading as="span">{children}</HiddenTextHeading>
  );
};
